import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import store from "./store/index";
import { IntlProvider } from "react-intl";

import messages_en from "./languages/en.json";
import messages_fr from "./languages/fr.json";
import GTM from "./GTM"

const messages = {
  en: messages_en,
  fr: messages_fr,
};

// get browser language without the region code
const language = navigator.language.split(/[-_]/)[0] || "fr";
// const language = "en";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <IntlProvider locale={language} messages={messages[language]}>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
    >
      <Provider store={store}>
        <GTM />
        <App />
      </Provider>
    </Auth0Provider>
  </IntlProvider>
);
